import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { HubPage } from "../pages/HubPage";
import { AsistenciaPage } from "../pages/AsistenciaPage";
import { ComunicadosPage } from "../pages/ComunicadosPage";
import { IndicadoresPage } from "../pages/IndicadoresPage";
import { AdminPage } from "../pages/AdminPage";
import { AdminPageBanner } from "../pages/AdminPageBanner";
import { AdminPageComunicados } from "../pages/AdminPageComunicados";
import { AdminPageMarca } from "../pages/AdminPageMarca";
import { AdminPageSucursal } from "../pages/AdminPageSucursal";
import { AdminPageRol } from "../pages/AdminPageRol";
import { AdminPageProductos } from "../pages/AdminPageProductos";
import { ReclasificacionPage } from "../pages/ReclasificacionPage";
import { AdminPageCatalogo } from "../pages/AdminPageCatalogo";
import { AdminPageVersion } from "../pages/AdminPageVersion";
import { AdminPageSeccion } from "../pages/AdminPageSeccion";
import { AdminPageAcessos } from "../pages/AdminPageAcessos";
import { ReportePage } from "../pages/ReportePage";
import { VistaHorarios } from "../pages/VistaHorarios";
import { MesaServiciosPage } from "../pages/MesaServiciosPage";
import { ReportesPage } from "../pages/ReportesPage";
import { ReporteUsabilidadPage } from "../pages/ReporteUsabilidadPage";
import { AdminPageNotificaciones } from "../pages/AdminPageNotificaciones";
import { AdminPageFormasPago } from "../pages/AdminPageFormasPago";
import { AdminPageMarcaATB} from "../pages/AdminPageMarcaATB"
import { AdminPageTipoATB} from "../pages/AdminPageTipoATB"
import { AdminPageModeloATB } from "../pages/AdminPageModeloATB"
import { VistaATB} from "../pages/VistaATB"

export const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/Home" element={<HomePage />} />
      <Route exact path="/Hub" element={<HubPage />} />
      <Route exact path="/MesaServiciosPage" element={<MesaServiciosPage />} />
      <Route exact path="/Asistencia" element={<AsistenciaPage />} />
      <Route exact path="/Comunicados" element={<ComunicadosPage />} />
      <Route exact path="/Indicadores" element={<IndicadoresPage />} />
      <Route exact path="/Reclasificacion" element={<ReclasificacionPage />} />
      <Route exact path="/ReporteCierreDia" element={<ReportePage />} />
      <Route exact path="/Reporte" element={<ReportesPage />} />
      <Route exact path="/Horarios" element={<VistaHorarios />} />
      <Route exact path="/Administracion" element={<AdminPage />} />
      <Route exact path="/AdministracionBanner" element={<AdminPageBanner />} />

      <Route
        exact
        path="/ReporteUsabilidad"
        element={<ReporteUsabilidadPage />}
      />
      <Route
        exact
        path="/AdministracionComunicados"
        element={<AdminPageComunicados />}
      />
      <Route exact path="/AdministracionMarca" element={<AdminPageMarca />} />
      <Route
        exact
        path="/AdministracionSucursal"
        element={<AdminPageSucursal />}
      />
      <Route exact path="/AdministracionRol" element={<AdminPageRol />} />
      <Route
        exact
        path="/AdministracionCatalogoRecoleción"
        element={<AdminPageCatalogo />}
      />
      <Route
        exact
        path="/AdministracionCatalogoProductos"
        element={<AdminPageProductos />}
      />
      <Route
        exact
        path="/AdministracionCatalogoVersion"
        element={<AdminPageVersion />}
      />
      <Route
        exact
        path="/AdministracionCatalogoSeccion"
        element={<AdminPageSeccion />}
      />
      <Route
        exact
        path="/AdministracionCatalogoAcessos"
        element={<AdminPageAcessos />}
      />
      <Route
        exact
        path="/AdministracionNotificaciones"
        element={<AdminPageNotificaciones />}
      />
      <Route
        exact
        path="/AdministracionFormasPago"
        element={<AdminPageFormasPago />}
      />
       <Route
        exact
        path="/AdministracionMarcaATB"
        element={<AdminPageMarcaATB />}
      />
      <Route
        exact
        path="/AdministracionTipoATB"
        element={<AdminPageTipoATB />}
      />
      <Route
        exact
        path="/AdministracionModeloATB"
        element={<AdminPageModeloATB />}
      />
      <Route
        exact
        path="/TerminalesBancarias"
        element={<VistaATB />}
      />
      <Route exact path="/*" element={<Navigate to="/Home" />} />
    </Routes>
  );
};
