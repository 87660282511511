import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import InputForm from "./utils/InputForm";
import { AddFetchProducto } from "../Api/Administracion/AddFetchProducto";
import { PutFetchProducto } from "../Api/Administracion/PutFetchProducto";

export const AdministrationProductoAddForm = ({
  selectedItem,
  fetchData,
  setShowModal,
  marca,
  modalKey
}) => {
  const objDefault = {
    FIIDPRODUCTO: "",
    FIIDPAIS: "1",
    FCNOMBREPRODUCTO: "",
    FCNOMBREMARCA: "",
    FIACTIVO: 1,
    FCIDPRODUCTO: "",
    FIIDMARCA: "6",
    FIIDTIPOPRODUCTO: "1",
    FIVISIBLE: 1,
    FCTIEMPODESCONGELADO: "",
    FCHORNEO: {
      FNITEMSXCHAROLA: "",
      FNTIEMPO: "",
      FNTEMPERATURA: "",
      FCTIPOHORNO: "",
      FNMAXCHAROLASHORNO: "",
      FNUNIDADESXHORNO: "",
    },
    FCURLIMAGEN: "",
  };
  const [formData, setFormData] = useState(objDefault);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let defaultChorneo = {
      FNITEMSXCHAROLA: "",
      FNTIEMPO: "",
      FNTEMPERATURA: "",
      FCTIPOHORNO: "",
      FNMAXCHAROLASHORNO: "",
      FNUNIDADESXHORNO: "",
    };

    if (selectedItem && selectedItem.FCHORNEO) {
      try {
        const obj = JSON.parse(selectedItem.FCHORNEO);
        defaultChorneo = {
          FNITEMSXCHAROLA: obj.FNITEMSXCHAROLA || "",
          FNTIEMPO: obj.FNTIEMPO || "",
          FNTEMPERATURA: obj.FNTEMPERATURA || "",
          FCTIPOHORNO: obj.FCTIPOHORNO || "",
          FNMAXCHAROLASHORNO: obj.FNMAXCHAROLASHORNO || "",
          FNUNIDADESXHORNO: obj.FNUNIDADESXHORNO || "",
        };
      } catch (e) {
        //console.log("Error al parsear FCHORNEO:", e);
      }
    }

    //onsole.log("selected", selectedItem)
    const newObj = {
      FIIDPRODUCTO: selectedItem ? selectedItem.FIIDPRODUCTO : "",
      FIIDPAIS: selectedItem ? selectedItem.FIIDPAIS : "1",
      FCNOMBREPRODUCTO: selectedItem ? selectedItem.FCNOMBREPRODUCTO : "",
      FCNOMBREMARCA: selectedItem ? selectedItem.FCNOMBREMARCA : "",
      FIACTIVO: selectedItem ? selectedItem.FIACTIVO : 1,
      FCIDPRODUCTO: selectedItem ? selectedItem.FCIDPRODUCTO : "",
      FIIDMARCA: selectedItem ? selectedItem.FIIDMARCA : "6",
      FIIDTIPOPRODUCTO: selectedItem ? selectedItem.FIIDTIPOPRODUCTO : "1",
      FIVISIBLE: selectedItem ? selectedItem.FIVISIBLE : 1,
      FCTIEMPODESCONGELADO: selectedItem
        ? selectedItem.FCTIEMPODESCONGELADO
        : "",
      FCHORNEO: defaultChorneo,
      FCURLIMAGEN: selectedItem ? selectedItem.FCURLIMAGEN : "",
      FCEDITARLIGA: false,
    };

    setFormData(newObj);
  }, [selectedItem,modalKey]);

  useEffect(() => {
    setErrors({})
  }, [selectedItem,modalKey])

 
  const handleChange = (event) => {
    const { name, value, files } = event.target;
    // Realiza una copia profunda del estado actual
    const updatedFormData = { ...formData };
  
    // Divide el nombre del campo en partes usando el punto como delimitador
    const fieldNames = name.split(".");
    if (fieldNames.length === 1) {
      // Si solo hay un nivel, actualiza directamente el campo principal
      updatedFormData[name] = value;
    } else {
      // Si hay más de un nivel, actualiza el campo anidado
      let currentObject = updatedFormData;
      for (let i = 0; i < fieldNames.length - 1; i++) {
        const fieldName = fieldNames[i];
        currentObject = currentObject[fieldName];
      }
      const lastFieldName = fieldNames[fieldNames.length - 1];
      currentObject[lastFieldName] = value;
    }
  
    // Manejar la lógica para campos de imagen
    if (name === "FCURLIMAGEN" && files && files.length > 0) {
      const imageFile = files[0];
      if (imageFile && imageFile.type.includes("image/png")) {
        const reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onloadend = () => {
          setFormData({ ...updatedFormData, [name]: reader.result });
          // setImage(reader.result);
        };
      } else {
        alert("Por favor selecciona un archivo PNG válido.");
      }
    } else {
      // Validaciones específicas para ciertos campos
      const numericValue = value.replace(/[^0-9]/g, "");
      if (name === "FCIDPRODUCTO") {
        if (value !== numericValue) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            FCIDPRODUCTO: "El ID del producto debe contener solo dígitos.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            FCIDPRODUCTO: "El ID del producto debe contener hasta 10 dígitos.",
          }));
        }
        updatedFormData[name] = numericValue.slice(0, 10);
      } else if (name === "FCNOMBREPRODUCTO" && value.length > 30) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          FCNOMBREPRODUCTO: "El nombre no debe exceder 30 caracteres.",
        }));
        return; // No actualizar el estado si hay un error
      } else if (name === "FCTIEMPODESCONGELADO") {
        if (value !== numericValue) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            FCTIEMPODESCONGELADO: "El tiempo de descongelado solo es numérico.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            FCTIEMPODESCONGELADO: "El tiempo de descongelado es numerico y son minutos.",
          }));
        }
        updatedFormData[name] = numericValue.slice(0, 5);
      } else {
        // Establece el nuevo estado para campos que no son de imagen y pasan las validaciones
        setFormData(updatedFormData);
      }
    }
  
    // Actualizar el estado final del formulario
    setFormData(updatedFormData);
  };
  
  

  const fetchHandleUpdateCancel = () => {
    setFormData(objDefault);
    setShowModal(false);
  };

  const fetchHandleUpdate = async () => {
    const errors = {};
    if (formData.FCIDPRODUCTO === "") {
      errors.FCIDPRODUCTO = "Ingresa el valor de producto Id";
    } 
    if (formData.FCNOMBREPRODUCTO === "") {
      errors.FCNOMBREPRODUCTO = "Ingresa el valor de nombre";
    } 
    if (formData.FCTIEMPODESCONGELADO === "") {
      errors.FCTIEMPODESCONGELADO = "Ingresa el valor de tiempo de descongelado";
    } else if (!/^[0-9]+$/.test(formData.FCTIEMPODESCONGELADO)) {
      errors.FCTIEMPODESCONGELADO = "El valor debe ser un número entero";
    }
    if (formData.FCHORNEO.FNITEMSXCHAROLA === "") {
      errors.FNITEMSXCHAROLA = "Ingresa el valor de items por charola";
    }else if (!/^[0-9]+$/.test(formData.FCHORNEO.FNITEMSXCHAROLA)) {
      errors.FNITEMSXCHAROLA = "El valor debe ser un número entero";
    }
    if (formData.FCHORNEO.FNTIEMPO === "") {
      errors.FNTIEMPO = "Ingresa el valor de tiempo de horneo";
    }else if (!/^[0-9]+$/.test(formData.FCHORNEO.FNTIEMPO)) {
      errors.FNTIEMPO = "El valor debe ser un número entero";
    }
    if (formData.FCHORNEO.FNTEMPERATURA === "") {
      errors.FNTEMPERATURA = "Ingresa el valor de temperatura";
    }else if (!/^[0-9]+$/.test(formData.FCHORNEO.FNTEMPERATURA)) {
      errors.FNTEMPERATURA = "El valor debe ser un número entero";
    }
    if (formData.FCHORNEO.FCTIPOHORNO === "") {
      errors.FCTIPOHORNO = "Ingresa el valor de tipo de horno";
    }
    if (formData.FCHORNEO.FNMAXCHAROLASHORNO === "") {
      errors.FNMAXCHAROLASHORNO = "Ingresa el valor maximo de charolas por horno";
    } else if (!/^[0-9]+$/.test(formData.FCHORNEO.FNMAXCHAROLASHORNO)) {
      errors.FNMAXCHAROLASHORNO = "El valor debe ser un número entero";
    }
    if (formData.FCHORNEO.FNUNIDADESXHORNO === "") {
      errors.FNUNIDADESXHORNO = "Ingresa el valor de unidades por horno";
    }else if (!/^[0-9]+$/.test(formData.FCHORNEO.FNUNIDADESXHORNO)) {
      errors.FNUNIDADESXHORNO = "El valor debe ser un número entero";
    }
    if (formData.FCURLIMAGEN === "") {
      errors.FCURLIMAGEN = "Selecione la imagen";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors("");
      sendData();
    }
    
    function sendData(){
      setLoading(true)
      const onFailure = (error) => {
        //console.log("error",error)
        setLoading(false)
        alert(error);
      };
      const onSuccess = ({ data }) => {
        setLoading(false)
        if (data.statusCode === 201) {
          setFormData(objDefault);
          fetchData();
          setShowModal(false);
          alert("Envío exitoso");
        } else {
          alert(data.error);
        }
      };

      // Realiza la validación del campo FCURLIMAGEN con la expresión regular
      const isUrlValid =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/.test(
          formData.FCURLIMAGEN
        );

      //console.log("form", formData);
      const nameBrand = marca.find(
        (it) => it.FIMARCAID === parseInt(formData.FIIDMARCA)
      );
      // Si es válida, envía el campo FCURLIMAGEN como una cadena vacía, de lo contrario, envía el valor actual
      const formDataToSend = {
        ...formData,
        FCURLIMAGEN: isUrlValid ? "" : formData.FCURLIMAGEN,
        FCHORNEO: JSON.stringify(formData.FCHORNEO),
        FCIDPRODUCTO: parseInt(formData.FCIDPRODUCTO),
        FIIDMARCA: formData.FIIDMARCA,
        FCNOMBREMARCA: nameBrand.FCNOMBRE,
        FCTIEMPODESCONGELADO: parseInt(formData.FCTIEMPODESCONGELADO),
        FIIDTIPOPRODUCTO: parseInt(formData.FIIDTIPOPRODUCTO),
      };

      if (selectedItem) {
        PutFetchProducto(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
      } else {
        AddFetchProducto(formDataToSend)
          .then(onSuccess)
          .catch(onFailure);
      }
    }
      
    
  };

  return (
    <Row justify="space-around">
      <Col span={24}>
        <div className="input-content-titulo">
          {selectedItem ? "Editar" : "Agregar"} Producto
        </div>
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCIDPRODUCTO}
          name={"FCIDPRODUCTO"}
          type={"text"}
          label={"Id producto"}
          disabled={selectedItem ? true : false} //deshabilita en caso de que sea edit
        />
         {errors && <div className="error-input-data">{errors.FCIDPRODUCTO}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCNOMBREPRODUCTO}
          name={"FCNOMBREPRODUCTO"}
          type={"text"}
          label={"Nombre"}
        />
        {errors && <div className="error-input-data">{errors.FCNOMBREPRODUCTO}</div>}
      </Col>
      <Col xs={24} md={10}>
        <label className="text-context-inputs">Marca: </label>
        <select
          className="input-content"
          value={formData.FIIDMARCA}
          onChange={handleChange}
          name={"FIIDMARCA"}
        >
          <option value="6">STARBUCKS</option>
        </select>
        {errors && <div className="error-input-data">{errors.FIIDMARCA}</div>}
      </Col>
      <Col xs={24} md={10}>
        <InputForm
          handleChange={handleChange}
          value={formData.FCTIEMPODESCONGELADO}
          name={"FCTIEMPODESCONGELADO"}
          type={"number"}
          label={"Tiempo descongelado"}
        />
        {errors && <div className="error-input-data">{errors.FCTIEMPODESCONGELADO}</div>}
      </Col>

      <Col xs={24} md={22}>
        <label className="text-context-inputs">Imagen:</label>
        <div className="row-image-banner">
          <div className="image-content">
            {formData.FCURLIMAGEN !== "" && (
              <div>
                <img
                  src={formData.FCURLIMAGEN}
                  alt="Imagen seleccionada"
                  style={{
                    maxWidth: "100px",
                    height: "50px",
                  }}
                />
              </div>
            )}
          </div>
          <div className="file-selectadd" id="src-fileadd">
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              className="input-content-img"
              name={"FCURLIMAGEN"}
            />
          </div>
        </div>
        {errors && <div className="error-input-data">{errors.FCURLIMAGEN}</div>}
      </Col>
      <Col xs={24} md={22}>
        <label className="text-context-inputs-horario">Horneo</label>
        <div className="container-row-horarios-sucursal">
          <div>
            <label className="text-context-inputs-horario-title">
              Items por charola
            </label>
            <input
              type="text"
              name="FCHORNEO.FNITEMSXCHAROLA"
              onChange={handleChange}
              value={formData.FCHORNEO.FNITEMSXCHAROLA}
              className="input-content"
            />
            {errors && <div className="error-input-data">{errors.FNITEMSXCHAROLA}</div>}
          </div>
          <div>
            <label className="text-context-inputs-horario-title">
              Tiempo de horneo
            </label>
            <input
              type="text"
              name="FCHORNEO.FNTIEMPO"
              onChange={handleChange}
              value={formData.FCHORNEO.FNTIEMPO}
              className="input-content"
            />
            {errors && <div className="error-input-data">{errors.FNTIEMPO}</div>}
          </div>
          <div>
            <label className="text-context-inputs-horario-title">
              Temperatura de horneo
            </label>
            <input
              type="text"
              name="FCHORNEO.FNTEMPERATURA"
              onChange={handleChange}
              value={formData.FCHORNEO.FNTEMPERATURA}
              className="input-content"
            />
             {errors && <div className="error-input-data">{errors.FNTEMPERATURA}</div>}
          </div>
        </div>

        <div className="container-row-horarios-sucursal">
          <div>
            <label className="text-context-inputs-horario-title">
              Tipo de horno
            </label>
            <input
              type="text"
              name="FCHORNEO.FCTIPOHORNO"
              onChange={handleChange}
              value={formData.FCHORNEO.FCTIPOHORNO}
              className="input-content"
            />
            {errors && <div className="error-input-data">{errors.FCTIPOHORNO}</div>}
          </div>
          <div>
            <label className="text-context-inputs-horario-title">
              Charolas por horno
            </label>
            <input
              type="text"
              name="FCHORNEO.FNMAXCHAROLASHORNO"
              onChange={handleChange}
              value={formData.FCHORNEO.FNMAXCHAROLASHORNO}
              className="input-content"
            />
            {errors && <div className="error-input-data">{errors.FNMAXCHAROLASHORNO}</div>}
          </div>
          <div>
            <label className="text-context-inputs-horario-title">
              Unidades por horno
            </label>
            <input
              type="text"
              name="FCHORNEO.FNUNIDADESXHORNO"
              onChange={handleChange}
              value={formData.FCHORNEO.FNUNIDADESXHORNO}
              className="input-content"
            />
            {errors && <div className="error-input-data">{errors.FNUNIDADESXHORNO}</div>}
          </div>
        </div>
      </Col>

      <div className="modal-footer-edith">
        <button
            onClick={fetchHandleUpdateCancel}
            type="submit"
            className="primary-button-cancel button-modal"
          >
            Cancelar
          </button>
        <button
          onClick={fetchHandleUpdate}
          type="submit"
          className={`button-modal ${
            isLoading ? "primary-button-disable" : "primary-button"
          }`}
          disabled={isLoading}
        >
          {isLoading ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </Row>
  );
};
